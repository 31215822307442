import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const AuthForm = ({ setIsLoggedIn }) => {
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    setIsSignUpMode(true);
    resetForm();
  };

  const handleSignInClick = () => {
    setIsSignUpMode(false);
    resetForm();
  };

  const resetForm = () => {
    setUsername("");
    setEmail("");
    setMobileNumber("");
    setPassword("");
  };

  const handleSignInSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://mentalhealth.bwsoft.in/api/api/login/",
        {
          username,
          password,
        }
      );
      // Show success message with SweetAlert2
      Swal.fire({
        title: "Login successful!",
        text: "You have successfully logged in.",
        icon: "success",
        confirmButtonText: "Okay",
      });
      setIsLoggedIn(true); // Set login state
      navigate("/");
      console.log("Login response:", response.data);
      const { user_id } = response.data;
      localStorage.setItem("user_id", user_id); // Stores user_id in localStorage
      console.log("Login response:", response.data);
    } catch (error) {
      // Show error message with SweetAlert2
      Swal.fire({
        title: "Login failed!",
        text: "Please check your credentials and try again.",
        icon: "error",
        confirmButtonText: "Retry",
      });
      console.error("Login error:", error);
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://mentalhealth.bwsoft.in/api/api/register/",
        {
          username,
          email,
          mobile_number: mobileNumber,
          password,
        }
      );
      // Show success message with SweetAlert2
      Swal.fire({
        title: "Registration successful!",
        text: "You can now log in with your new account.",
        icon: "success",
        confirmButtonText: "Okay",
      });
      console.log("Registration response:", response.data);
      handleSignInClick(); // Switch to sign-in mode after registration
    } catch (error) {
      // Show error message with SweetAlert2
      Swal.fire({
        title: "Registration failed!",
        text: "Please try again later.",
        icon: "error",
        confirmButtonText: "Retry",
      });
      console.error("Registration error:", error);
    }
  };

  return (
    <div className={`container ${isSignUpMode ? "sign-up-mode" : ""}`}>
      <div className="forms-container">
        <div className="signin-signup">
          {/* Sign In Form */}
          <form onSubmit={handleSignInSubmit} className="sign-in-form">
            <h2 className="title">Sign in</h2>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <i
                className={`fas ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`} // Toggle eye icon
                onClick={() => setPasswordVisible(!passwordVisible)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              ></i>
            </div>
            <input type="submit" value="Login" className="btn solid" />
          </form>

          {/* Sign Up Form */}
          <form onSubmit={handleSignUpSubmit} className="sign-up-form">
            <h2 className="title">Sign up</h2>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="input-field">
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-field">
              <i className="fas fa-phone"></i>
              <input
                type="text"
                placeholder="Mobile Number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                required
              />
            </div>
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <i
                className={`fas ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`} // Toggle eye icon
                onClick={() => setPasswordVisible(!passwordVisible)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              ></i>
            </div>
            <input type="submit" className="btn" value="Sign up" />
          </form>
        </div>
      </div>

      {/* Panels */}
      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>Welcome Buddy</h3>
            <p>
              Take control of your stress and enhance your well-being with our
              tools and resources.
            </p>
            <button className="btn transparent" onClick={handleSignUpClick}>
              Sign up
            </button>
          </div>
          <img
            src="https://i.ibb.co/6HXL6q1/Privacy-policy-rafiki.png"
            className="image"
            alt="Privacy Policy Illustration"
          />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3>Welcome Buddy</h3>
            <p>Let's get Start and explore</p>
            <button className="btn transparent" onClick={handleSignInClick}>
              Sign in
            </button>
          </div>
          <img
            src="https://i.ibb.co/nP8H853/Mobile-login-rafiki.png"
            className="image"
            alt="Login Illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
