import React, { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/mainpages/Header";
import Home from "./components/pages/Home";
import AuthForm from "./components/pages/Authform";
import Footer from "./components/mainpages/Footer";
import "./App.css";
import Stresstest from "./components/pages/Stresstest";
import Score from "./components/pages/Score";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <BrowserRouter>
      {isLoggedIn && <Header />}
      <Routes>
        <Route
          path="/"
          element={isLoggedIn ? <Home /> : <Navigate to="/AuthForm" />}
        />
        <Route
          path="/Stresstest"
          element={isLoggedIn ? <Stresstest /> : <Navigate to="/AuthForm" />}
        />
        <Route
          path="/Score"
          element={isLoggedIn ? <Score /> : <Navigate to="/AuthForm" />}
        />
        <Route
          path="/AuthForm"
          element={<AuthForm setIsLoggedIn={setIsLoggedIn} />}
        />
      </Routes>
      {isLoggedIn && <Footer />}
    </BrowserRouter>
  );
}

export default App;
