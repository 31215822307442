import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer>
        <div class="footer-links">
          <Link to="/">Home </Link>
          <Link to="/Stresstest"> Stress Test </Link>
          <Link to="/Stresstest">Signup/Login </Link>
        </div>
        <div class="footer-social">
          <Link to="#">
            <ion-icon
              name="logo-facebook"
              class="icon"
              alt="Facebook"
            ></ion-icon>
          </Link>
          <Link to="#">
            <ion-icon name="logo-twitter" class="icon" alt="Twitter"></ion-icon>
          </Link>
          <Link to="#">
            <ion-icon
              name="logo-linkedin"
              class="icon"
              alt="LinkedIn"
            ></ion-icon>
          </Link>
        </div>
        <div class="footer-contact">
          <p>
            <strong>Email:</strong>{" "}
            <a
              href="mailto:pplcallmesesha@gmail.com"
              style={{ color: "white" }}
            >
              pplcallmesesha@gmail.com
            </a>
          </p>
          <p>
            <strong>Phone:</strong>{" "}
            <a href="tel:+917305651408" style={{ color: "white" }}>
              7305651408
            </a>
          </p>
        </div>

        <div class="newsletter-signup">
          <input type="email" placeholder="Subscribe to our newsletter" />
          <button class="cta-btn">Subscribe</button>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
