import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Score = () => {
  const location = useLocation();
  const { score } = location.state || { score: 0 };
  const userId = localStorage.getItem("user_id");

  const [piePercent, setPiePercent] = useState(0);

  useEffect(() => {
    const calculatedPercent = (score / 75) * 100;
    setPiePercent(calculatedPercent);
  }, [score]);

  const getStrokeColor = (percent) => {
    if (percent < 40) {
      return "blue"; // Below 30%
    } else if (percent < 80) {
      return "orange"; // Between 30% and 60%
    } else {
      return "red"; // Above 60%
    }
  };

  const getSuggestion = (percent) => {
    if (percent < 40) {
      return (
        <div>
          <h3>Low Stress Level</h3>
          <img src="../assets/img/logo.png" alt="happy"></img>
          <p className="suggestion">
            You're doing great! To keep your stress level low, consider engaging
            in activities like yoga, deep breathing exercises, or meditation.
            These can help you maintain a calm and balanced mind.
          </p>

          <div className="score-button">
            <Link
              to="https://subway-surfers.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="score-btn">subway-surfers</button>
            </Link>
            <Link to="https://poki.com/en/car">
              <button className="score-btn"> Car games</button>
            </Link>
          </div>
        </div>
      );
    } else if (percent < 80) {
      return (
        <div>
          <h3>Moderate Stress Level</h3>
          <img src="../assets/img/sad.png" alt="sad"></img>
          <p className="suggestion">
            It's normal to feel a bit stressed, but there are fun ways to
            relieve it! Consider watching a movie, playing video games, or
            spending time with friends. A little relaxation can help boost your
            mood and recharge your energy.
          </p>
          <Link to="tel:+917305651408">
            <button className="score-btn">Feel Free To Contact</button>
          </Link>
        </div>
      );
    } else {
      return (
        <div>
          <h3>High Stress Level</h3>
          <img src="../assets/img/cry.png" alt="cry"></img>
          <p className="suggestion">
            Your stress level seems high, and it's important to take care of
            yourself. It might be a good idea to consult a doctor or a
            healthcare professional to get personalized advice. It's always okay
            to seek help when you're feeling overwhelmed.
          </p>
          <div className="score-button">
            <Link
              to="https://www.apollo247.com/specialties/psychiatry"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="score-btn">consult a doctor</button>
            </Link>
            <Link to="https://www.spill.chat/mental-health-statistics/workplace-stress-statistics">
              <button className="score-btn"> work realated stress</button>
            </Link>
          </div>
        </div>
      );
    }
  };

  const handlePostData = async () => {
    const suggestionText =
      getSuggestion(piePercent).props.children[2].props.children;
    const payload = {
      user: userId,
      text: suggestionText,
      percentage: Math.round(piePercent),
    };

    try {
      const response = await axios.post(
        "https://mentalhealth.bwsoft.in/api/api/responses/",
        payload
      );
      console.log("Data posted successfully:", response.data);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  useEffect(() => {
    handlePostData();
  }, [piePercent]);

  return (
    <div className="score-container">
      <section id="scorepage">
        <div className="hero-content">
          <h1>Stress Test Finished!</h1>
          <p>You scored {score} out of 25 points.</p>
        </div>
      </section>

      {/* Pie chart */}
      <div className="containerscore">
        <div className="pie">
          <CircularProgressbar
            value={piePercent}
            text={`${piePercent.toFixed(0)}%`}
            styles={{
              path: {
                stroke: getStrokeColor(piePercent),
              },
              trail: {
                stroke: "#e6e6e6",
              },
            }}
          />
        </div>

        <div className="code">
          <pre>
            <code className="language-json">
              {JSON.stringify(
                {
                  lineargradient: ["yellow", "#ff0000"],
                  round: true,
                  percent: piePercent,
                  colorCircle: "#e6e6e6",
                },
                null,
                2
              )}
            </code>
          </pre>
        </div>
      </div>
      <div id="scorepoints">
        <p> {getSuggestion(piePercent)} </p>
      </div>
    </div>
  );
};

export default Score;
