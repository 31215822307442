import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Stresstest = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [score, setScore] = useState(0);
  const questions = [
    {
      question: "How would you rate your current workload?",
      options: [
        "I feel overwhelmed and stressed",
        "I am managing but a bit busy",
        "I feel in control and productive",
      ],
      points: {
        "I feel in control and productive": 2,
        "I am managing but a bit busy": 3,
        "I feel overwhelmed and stressed": 5,
      },
    },
    {
      question: "How often do you take breaks during work?",
      options: [
        "Rarely, I work through the day",
        "Sometimes, I take short breaks",
        "I regularly take breaks to stay refreshed",
      ],
      points: {
        "I regularly take breaks to stay refreshed": 2,
        "Sometimes, I take short breaks": 3,
        "Rarely, I work through the day": 5,
      },
    },
    {
      question: "How do you approach urgent tasks or deadlines?",
      options: [
        "I panic and often miss them",
        "I try to manage but feel rushed",
        "I plan and handle them calmly",
      ],
      points: {
        "I plan and handle them calmly": 2,
        "I try to manage but feel rushed": 3,
        "I panic and often miss them": 5,
      },
    },
    {
      question: "How often do you feel anxious about your work performance?",
      options: [
        "Constantly, it’s overwhelming",
        "Occasionally, it’s manageable",
        "Rarely, I stay calm under pressure",
      ],
      points: {
        "Rarely, I stay calm under pressure": 2,
        "Occasionally, it’s manageable": 3,
        "Constantly, it’s overwhelming": 5,
      },
    },
    {
      question: "How do you handle code reviews or feedback from peers?",
      options: [
        "I feel defensive and uncomfortable",
        "I try to accept it but feel pressured",
        "I take it positively as a learning experience",
      ],
      points: {
        "I take it positively as a learning experience": 2,
        "I try to accept it but feel pressured": 3,
        "I feel defensive and uncomfortable": 5,
      },
    },
    {
      question: "How do you feel about handling multiple projects at once?",
      options: [
        "I get stressed and struggle to keep up",
        "I can manage but it feels challenging",
        "I stay organized and handle them well",
      ],
      points: {
        "I stay organized and handle them well": 2,
        "I can manage but it feels challenging": 3,
        "I get stressed and struggle to keep up": 5,
      },
    },
    {
      question: "How often do you work extra hours to complete tasks?",
      options: [
        "Frequently, it’s part of my routine",
        "Sometimes, when there’s an urgent need",
        "Rarely, I complete work within hours",
      ],
      points: {
        "Rarely, I complete work within hours": 2,
        "Sometimes, when there’s an urgent need": 3,
        "Frequently, it’s part of my routine": 5,
      },
    },
    {
      question: "How comfortable are you in asking for help when stuck?",
      options: [
        "I feel hesitant and rarely ask",
        "I ask only if absolutely necessary",
        "I am comfortable seeking help",
      ],
      points: {
        "I am comfortable seeking help": 2,
        "I ask only if absolutely necessary": 3,
        "I feel hesitant and rarely ask": 5,
      },
    },
    {
      question:
        "How often do you encounter interruptions that break your focus?",
      options: [
        "Constantly, it’s hard to concentrate",
        "Occasionally, but I manage to refocus",
        "Rarely, I maintain good focus",
      ],
      points: {
        "Rarely, I maintain good focus": 2,
        "Occasionally, but I manage to refocus": 3,
        "Constantly, it’s hard to concentrate": 5,
      },
    },
    {
      question:
        "How often do you engage in self-learning or skill improvement?",
      options: [
        "Rarely, I’m too busy with tasks",
        "Sometimes, when I have free time",
        "Often, I prioritize skill growth",
      ],
      points: {
        "Often, I prioritize skill growth": 2,
        "Sometimes, when I have free time": 3,
        "Rarely, I’m too busy with tasks": 5,
      },
    },
    {
      question: "How do you feel about your work-life balance?",
      options: [
        "It’s poor, work dominates my life",
        "It’s okay but could be better",
        "It’s good, I maintain a balance",
      ],
      points: {
        "It’s good, I maintain a balance": 2,
        "It’s okay but could be better": 3,
        "It’s poor, work dominates my life": 5,
      },
    },
    {
      question: "How do you handle troubleshooting difficult bugs?",
      options: [
        "I feel overwhelmed and avoid them",
        "I tackle them but feel frustrated",
        "I stay calm and work systematically",
      ],
      points: {
        "I stay calm and work systematically": 2,
        "I tackle them but feel frustrated": 3,
        "I feel overwhelmed and avoid them": 5,
      },
    },
    {
      question: "How often do you collaborate with colleagues on projects?",
      options: [
        "Rarely, I prefer working alone",
        "Sometimes, when needed",
        "Often, I enjoy team collaboration",
      ],
      points: {
        "Often, I enjoy team collaboration": 2,
        "Sometimes, when needed": 3,
        "Rarely, I prefer working alone": 5,
      },
    },
    {
      question: "How well do you manage emails and other communications?",
      options: [
        "I feel overwhelmed and avoid them",
        "I check them regularly but get distracted",
        "I manage them effectively without distraction",
      ],
      points: {
        "I manage them effectively without distraction": 2,
        "I check them regularly but get distracted": 3,
        "I feel overwhelmed and avoid them": 5,
      },
    },
    {
      question: "How often do you feel supported by your team or management?",
      options: [
        "Rarely, I feel isolated",
        "Sometimes, but it could improve",
        "Often, I feel fully supported",
      ],
      points: {
        "Often, I feel fully supported": 2,
        "Sometimes, but it could improve": 3,
        "Rarely, I feel isolated": 5,
      },
    },
  ];

  const handleAnswerSelection = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleNextQuestion = () => {
    if (selectedAnswer) {
      setScore(
        (prevScore) =>
          prevScore + questions[currentQuestionIndex].points[selectedAnswer]
      );

      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setSelectedAnswer(null);
      }
    }
  };

  const handleSubmit = () => {
    navigate("/Score", { state: { score } });
  };

  return (
    <div>
      <section id="hero">
        <div className="hero-content">
          <h1>Take this simple test and measure the stress in your life</h1>
        </div>
      </section>

      <section id="stresstest">
        <div className="stresstest-container">
          <h3>{questions[currentQuestionIndex].question}</h3>
          <ul>
            {questions[currentQuestionIndex].options.map((option, index) => (
              <li key={index}>
                <label>
                  <input
                    type="checkbox"
                    name="quiz-option"
                    value={option}
                    checked={selectedAnswer === option}
                    onChange={() => handleAnswerSelection(option)}
                  />
                  {option}
                </label>
              </li>
            ))}
          </ul>
          <br />
          <button
            className="ct-btn"
            onClick={
              currentQuestionIndex === questions.length - 1
                ? handleSubmit
                : handleNextQuestion
            }
            disabled={!selectedAnswer}
          >
            {currentQuestionIndex === questions.length - 1 ? "Submit" : "Next"}
          </button>
        </div>
      </section>
    </div>
  );
};

export default Stresstest;
