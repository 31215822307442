import React from "react";
import { Link } from "react-router-dom";
import {
  FaHeart,
  FaBed,
  FaDumbbell,
  FaComments,
  FaBookOpen,
  FaBrain,
  FaVirus,
  FaSmile,
  FaMeh,
  FaSadTear,
} from "react-icons/fa";

const Home = () => {
  return (
    <div>
      <section
        id="banner"
        className="banner"
        style={{
          backgroundImage: "url('../assets/img/banner.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "60vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <div className="banner-content">
          <h2>Welcome to Stress Relief Platform</h2>
          <p>
            Take control of your stress and enhance your well-being with our
            tools and resources.
          </p>
        </div>
      </section>
      <section id="hero">
        <div class="hero-content">
          <h1>TEST YOUR STRESS</h1>
          <p>
            Knowing your current level of stress is the first step in taking
            control
          </p>
          <Link to="/Stresstest">
            <button class="cta-btn">Get Your Stress Score</button>
          </Link>
        </div>
      </section>

      <section id="features">
        <h2>Manage Your Stress Level</h2>
        <div className="features-container">
          <div className="feature-card">
            <FaHeart className="icon" />
            <h3>Relaxation</h3>
            <p>
              Try deep breathing exercises, meditation, or yoga to reduce
              stress.
            </p>
          </div>
          <div className="feature-card">
            <FaBed className="icon" />
            <h3>Rest</h3>
            <p>Get enough sleep to help your body and mind recover.</p>
          </div>
          <div className="feature-card">
            <FaDumbbell className="icon" />
            <h3>Exercise</h3>
            <p>Engage in regular physical activity to boost your mood.</p>
          </div>
          <div className="feature-card">
            <FaComments className="icon" />
            <h3>Talk to Someone</h3>
            <p>Reach out to friends or professionals to share your feelings.</p>
          </div>
          <div className="feature-card">
            <FaBookOpen className="icon" />
            <h3>Resources</h3>
            <p>Explore helpful articles and workshops on stress management.</p>
          </div>
        </div>
      </section>

      <section id="how-it-works">
        <h2>How Stress Affects Our Health</h2>
        <div className="steps-container">
          <div className="step">
            <FaHeart className="icon" />
            <h3>Heart Health</h3>
            <p>
              Chronic stress can increase your risk of heart disease by raising
              blood pressure and inflammation.
            </p>
          </div>

          <div className="step">
            <FaBrain className="icon" />
            <h3>Brain Function</h3>
            <p>
              Stress can impair brain function, affecting memory, concentration,
              and decision-making.
            </p>
          </div>

          <div className="step">
            <FaDumbbell className="icon" />
            <h3>Physical Fitness</h3>
            <p>
              Stress can lead to fatigue, muscle tension, and poor posture,
              which can hinder physical fitness.
            </p>
          </div>

          <div className="step">
            <FaBed className="icon" />
            <h3>Sleep Quality</h3>
            <p>
              High levels of stress can lead to insomnia and poor sleep, which
              can affect overall well-being.
            </p>
          </div>

          <div className="step">
            <FaVirus className="icon" />
            <h3>Immune System</h3>
            <p>
              Stress weakens the immune system, making the body more susceptible
              to infections.
            </p>
          </div>
        </div>
      </section>

      <section id="resources">
        <h2>How to Find Your Stress Level</h2>
        <div className="resources-container">
          <div className="resource-card">
            <FaSmile size={50} color="green" />
            <h3>Low Stress</h3>
            <p>Feeling calm, relaxed, and in control of daily tasks.</p>
          </div>

          <div className="resource-card">
            <FaMeh size={50} color="orange" />
            <h3>Medium Stress</h3>
            <p>Occasionally overwhelmed, but still managing well.</p>
          </div>

          <div className="resource-card">
            <FaSadTear size={50} color="red" />
            <h3>High Stress</h3>
            <p>
              Constantly overwhelmed, with physical symptoms like headaches.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
